<template>
  <backoffice-container>
    <div class="d-flex mb-2">
      <h4>
        {{ $t("backoffice-menu.contact-info") }} 
      </h4>
    </div>

    <div>
      <!-- Links -->
      <Links
        @website="handleWebsite"
      />

      <!-- Emails -->
      <Emails />

      <!-- Phones -->
      <Phones />

      <!-- Locations -->
      <Locations class="pb-3" />

      <!-- Contact -->
      <Contact v-if="isSubcommunity" />

    </div>
  </backoffice-container>
</template>

<script>
import vSelect from 'vue-select';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
// import { required, max } from '@validations';
import BackofficeContainer from '@/backoffice/components/BackofficeContainer.vue';
// import { checkPermissionsForStaffs } from '@/@core/utils/roles-utils';
// import ErrorPlaceholder from '@/assets/images/placeholders/light/acceso_restringido.svg';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BackofficeContainer,
    vSelect,
    Links: () => import('@/backoffice/components/contact-info/Links.vue' /* webpackChunkName: "Links" */),
    Emails: () => import('@/backoffice/components/contact-info/Emails.vue' /* webpackChunkName: "Emails" */),
    Phones: () => import('@/backoffice/components/contact-info/Phones.vue' /* webpackChunkName: "Phones" */),
    Locations: () => import('@/backoffice/components/contact-info/Locations.vue' /* webpackChunkName: "Locations" */),
    Contact: () => import('@/backoffice/components/contact-info/Contact.vue' /* webpackChunkName: "Contact" */)
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      form: {
        website: '',
        joinURL: null,
        registrationURL: null,
      },
    };
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return {
      getValidationState,
    };
  },
  computed: {
    communityError() {
      return this.$store.getters.communityError;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    isSubcommunity() {
      return this.currentCollective.parentKey != null;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
  },
  watch: {

  },
  async created() {
    this.isStaff = this.currentCollective.isStaff;
    this.selectedLanguage = this.locale;
    this.form = { ...this.currentCollective };
    this.form.website = this.currentCollective.landing;
    this.links = this.currentCollective.links;
  },
  methods: {
    handleWebsite(website) {
      this.form.website = website;
    },
    handleRegistration(registration) {
      this.form.registrationURL = registration;
      this.form.joinURL = null;
    },
    handleJoin(join) {
      this.form.joinURL = join;
      this.form.registrationURL = null;
    },
    handleIntern() {
      this.form.joinURL = null;
      this.form.registrationURL = null;
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.settings-form {
  @include media-breakpoint-up(md) {
    width: 75%;
  }
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
}
.field.has-value:not(.no-label) .field-input[data-v-5b500588] {
  // Class of VueCtkDateTimePicker
  color: $dark;
}
.close-icon {
  z-index: 9;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: -10px;
}
.ql-container {
    height: 300px !important;
}
</style>